import { css } from '@emotion/core';

// Biennale
import BiennaleSemiBold from '../fonts/biennale-semi-bold.otf';
import BiennaleHeavy from '../fonts/biennale-heavy.otf';

export const BiennaleFont = css`
  @font-face {
    font-family: Biennale;
    font-weight: 600;
    src: url(${BiennaleSemiBold});
  }
  @font-face {
    font-family: Biennale;
    font-weight: 900;
    src: url(${BiennaleHeavy});
  }
`;
